<template>
  <div class="category-list">
    <el-button type="primary" plain style="margin-bottom: 10px;" size="small" @click="dialogCreateCategoryVisible = true"><i class="el-icon-plus"></i>新增分类</el-button>
    <el-table :data="tableDataCategory" style="width: 100%" border size="small" v-loading="loading">
      <el-table-column prop="id" label="ID" width="50" fixed="left"></el-table-column>
      <el-table-column prop="image" label="图片" width="100">
        <template slot-scope="scope">
          <el-image style="width: 30px; height: 30px;display: flex;justify-content: center;" :src="scope.row.image" fit="cover">
            <div slot="error" class="image-slot" style="margin: auto;">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="分类名称">
      </el-table-column>
      <el-table-column
        prop="goods_num"
        label="关联商品数">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="230">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row.id, scope.row.name, scope.row.image)">编辑</el-button>
          <el-button size="mini" @click="handleShowList(scope.$index, scope.row.id)">关联商品</el-button>
          <el-popconfirm title="确定要删除吗？" @confirm="handleDelete(scope.$index, scope.row.id)">
            <el-button slot="reference" size="mini" type="danger">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :page-size="15" :current-page.sync="page_category" layout="prev, pager, next" :total="category_count" @current-change="handle_page_category_current"></el-pagination>
    <el-dialog title="关联商品到分类" :visible.sync="goodsDialogVisible" width="60%" top="5vh" :before-close="handleTabClose">
      <el-tabs v-model="activeTabName" @tab-click="handleTabClick">
        <el-tab-pane label="已关联商品" name="linked">
          <el-table :data="tableDataLinked" style="width: 100%" max-height="650" border size="small" v-loading="loading">
            <el-table-column prop="id" label="ID" width="50" fixed="left"></el-table-column>
            <el-table-column prop="image" label="主图" width="80">
              <template slot-scope="scope">
                <el-image style="width: 30px; height: 30px;display: flex;justify-content: center;" :src="scope.row.image" fit="cover">
                  <div slot="error" class="image-slot" style="margin: auto;">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="商品名称"></el-table-column>
            <el-table-column prop="begin_time" label="定时上架时间" width="150"></el-table-column>
            <el-table-column prop="end_time" label="定时下架时间" width="150"></el-table-column>
            <el-table-column prop="status" label="状态" width="100">
              <template slot-scope="scope">
                <el-tag size="mini" type="success" v-if="scope.row.status === 1">出售中</el-tag>
                <el-tag size="mini" v-if="scope.row.status === 2">仓库中</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="110">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" plain @click="handleLink(scope.$index,2, scope.row.id)">取消关联</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background :page-size="10" :current-page.sync="page_linked" layout="prev, pager, next" :total="count_linked" @current-change="handle_page_linked_current"></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="未关联商品" name="tolink">
          <el-table :data="tableDataTolink" style="width: 100%" max-height="650" border size="small" v-loading="loading">
            <el-table-column prop="id" label="ID" width="50" fixed="left"></el-table-column>
            <el-table-column prop="image" label="主图" width="80">
              <template slot-scope="scope">
                <el-image style="width: 30px; height: 30px;display: flex;justify-content: center;" :src="scope.row.image" fit="cover">
                  <div slot="error" class="image-slot" style="margin: auto;">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="商品名称"></el-table-column>
            <el-table-column prop="begin_time" label="定时上架时间" width="150"></el-table-column>
            <el-table-column prop="end_time" label="定时下架时间" width="150"></el-table-column>
            <el-table-column prop="status" label="状态" width="100">
              <template slot-scope="scope">
                <el-tag size="mini" type="success" v-if="scope.row.status === 1">出售中</el-tag>
                <el-tag size="mini" v-if="scope.row.status === 2">仓库中</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="110">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" plain @click="handleLink(scope.$index, 1,scope.row.id)">关联</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background :page-size="10" :current-page.sync="page_tolink" layout="prev, pager, next" :total="count_tolink" @current-change="handle_page_tolink_current"></el-pagination>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog :title="isUpdate?'编辑分类':'新增分类'" :visible.sync="dialogCreateCategoryVisible" width="30%" :before-close="handleCreateCategoryClose" @close="handledialogXzflclose">
      <el-input v-model="category.name" placeholder="请输入分类名称"></el-input>
      <div style="margin-top: 10px;">
        <el-upload
          action="admin/category/image"
          name="image"
          ref="upload"
          :limit="1"
          :on-success="handleImageSuccess"
          :on-remove="handleImageRemove"
          :on-exceed="handleExceed"
          :file-list="fileList">
          <el-button size="small" type="primary">上传配图</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCreateCategoryVisible = false" size="small">取 消</el-button>
        <el-button v-if="isUpdate" type="primary" @click="handleUpdateCategory" size="small">更 新</el-button>
        <el-button v-else type="primary" @click="addCategory" size="small">新 增</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'GoodsList',
  data () {
    return {
      fileList: [],
      category: {
        name: '',
        image: ''
      },
      isUpdate: false,
      loading: false,
      active_category: '', // 当前分类ID
      page_category: 0, // 分类当前页
      category_count: 0, // 分类总条数
      tableDataCategory: [],
      page_linked: 0,
      count_linked: 0,
      tableDataLinked: [],
      page_tolink: 0,
      count_tolink: 0,
      tableDataTolink: [],
      activeTabName: 'linked',
      goodsDialogVisible: false,
      dialogCreateCategoryVisible: false
    }
  },
  methods: {
    handledialogXzflclose () {
      this.isUpdate = false
      this.category = {
        name: '',
        image: ''
      }
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    handleImageSuccess (response, file, fileList) {
      if (response.code === 0) {
        this.category.image = response.url
      }
    },
    handleImageRemove () {
      this.category.image = ''
    },
    handleCreateCategoryClose () {
      this.dialogCreateCategoryVisible = false
    },
    handleTabClose () {
      this.goodsDialogVisible = false
      this.getCategoryData()
    },
    handleTabClick (tab, event) {
      console.log(tab.name)
      if (tab.name === 'tolink') {
        this.getCategoryGoodsData(this.active_category, 2, this.page_tolink)
      }
      if (tab.name === 'linked') {
        this.getCategoryGoodsData(this.active_category, 1, this.page_linked)
      }
    },
    handleShowList (index, id) {
      this.goodsDialogVisible = true
      this.active_category = id
      this.getCategoryGoodsData(id, 1, 1)
    },
    handleEdit (index, id, name, image) {
      this.isUpdate = true
      this.dialogCreateCategoryVisible = true
      this.category = {
        name: name,
        image: image,
        id: id
      }
    },
    // 更新分类信息
    handleUpdateCategory () {
      var that = this
      this.axios.put('admin/category', that.category).then(function (response) {
        if (response.data.code === 0) {
          that.$message.success(response.data.msg)
          that.dialogCreateCategoryVisible = false
          that.getCategoryData()
        }
        if (response.data.code === 1) {
          that.$message.error(response.data.msg)
        }
      })
    },
    // type 1,关联，2，取消关联
    handleLink (index, type, id) {
      var that = this
      this.axios.put('admin/category/goods', {
        category_id: that.active_category,
        goods_id: id,
        type: type
      })
        .then(function (response) {
          if (response.data.res === 1) {
            that.$message.success('操作成功！')
            if (type === 1) {
              that.tableDataTolink.splice(index, 1)
            }
            if (type === 2) {
              that.tableDataLinked.splice(index, 1)
            }
          }
        })
    },
    handleDelete (index, id) {
      var that = this
      this.axios.delete('admin/category/' + id)
        .then(function (response) {
          if (response.data.code === 0) {
            that.$message.success(response.data.msg)
            that.tableDataCategory.splice(index, 1)
          }
        })
    },
    handle_page_category_current () {
      this.getCategoryData()
    },
    handle_page_linked_current () {
      this.getCategoryGoodsData(this.active_category, 1, this.page_linked)
    },
    handle_page_tolink_current () {
      this.getCategoryGoodsData(this.active_category, 2, this.page_tolink)
    },
    addCategory () {
      var that = this
      this.axios.post('admin/category', {
        name: that.category.name,
        image: that.category.image
      })
        .then(function (response) {
          if (response.data.code === 0) {
            that.$message({
              message: response.data.msg,
              type: 'success'
            })
            that.category = {}
            that.$refs.upload.clearFiles()
            that.getCategoryData()
            that.dialogCreateCategoryVisible = false
          }
          if (response.data.code === 1) {
            that.$message.error(response.data.msg)
          }
        })
    },
    getCategoryData () {
      var that = this
      that.loading = true
      this.axios.get('admin/category', {
        params: {
          page: that.page_category
        }
      })
        .then(function (response) {
          if (response.data.code === 0) {
            that.tableDataCategory = response.data.list
            that.category_count = response.data.count
            that.page_category = response.data.page
            that.loading = false
          }
        })
    },
    getCategoryGoodsData (id, type, page) {
      var that = this
      this.loading = true
      this.axios.get('admin/category/' + id + '/' + type + '/' + page)
        .then(function (response) {
          var res = response.data
          if (res.code === 0) {
            // 1代表已关联
            if (type === 1) {
              that.tableDataLinked = res.list
              that.count_linked = res.count
            }
            // 2代表未关联
            if (type === 2) {
              that.tableDataTolink = res.list
              that.count_tolink = res.count
            }
            that.loading = false
          }
        })
    }
  },
  mounted () {
    this.getCategoryData()
  }
}
</script>

<style scoped>

</style>
